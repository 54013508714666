<template>
	<div style="display: inline-block">
		<a-button :disabled="!processStatus.currentUserCanProcess" type="primary" @click="SendAudit">
			<img class="icon-btn" src="@/assets/Save.png" />{{ $t('operate.auditResult') }}
		</a-button>
		<a-modal
			v-drag-modal
			:title="$t('table.loanReview.Seeting.modal.title')"
			:dialog-style="{ top: '60px' }"
			v-model="Modal.show"
			:destroyOnClose="true"
			@ok="handleOk"
			width="568px"
			:afterClose="
				() => {
					Modal.recommendAmount = ''
					Modal.otherRecommendAmount = 0
				}
			"
		>
			<div class="record-content modal-content">
				<div style="overflow: hidden">
					<record :loanPreCreditResponse="loanPreCreditResponse" :checkRecords="checkRecords" :riskBasedFinancing="riskBasedFinancing"></record>
				</div>
				<a-row v-if="processStatus.statusCode === 'disburse_info_check'">
					<a-radio-group v-model="Modal.pass" style="display: block">
						<a-col v-for="item in [1, 0]" :key="item" :span="12" style="padding: 15px">
							<a-radio :value="item">
								{{ $t(`table.loanReview.Seeting.modal.statusMap.${item}`) }}
							</a-radio>
						</a-col>
					</a-radio-group>
				</a-row>
				<div v-else>
					<div v-if="processStatus.processNode.id === 'apply_info_confirm_node'">
						<a-row>
							<a-radio-group v-model="Modal.pass" style="display: block">
								<a-col v-for="item in [1, -2, 0]" :key="item" :span="8" style="padding: 15px">
									<a-radio :value="item" v-if="item === 0">
										{{ $t(`table.loanReview.Seeting.modal.statusClientMap.${item}`) }}
									</a-radio>
									<a-radio :value="item" v-else @click="comfirmTip">
										{{ $t(`table.loanReview.Seeting.modal.statusClientMap.${item}`) }}
									</a-radio>
								</a-col>
							</a-radio-group>
						</a-row>
						<a-row style="padding: 0 15px 15px">
							<a-col :span="9"> {{ $t('table.finance.cloums.amount') }}: </a-col>
							<a-col :span="15" style="text-transform: capitalize"
								>{{ processStatus.recommendAmountType + ' ' + processStatus.recommendAmount }}
							</a-col>
						</a-row>
					</div>
					<div v-else>
						<a-row>
							<a-radio-group v-model="Modal.pass" style="display: block">
								<a-col v-for="item in [1, 0]" :key="item" :span="12" style="padding: 15px">
									<a-radio :value="item">
										{{ $t(`table.loanReview.Seeting.modal.statusMap.${item}`) }}
									</a-radio>
								</a-col>
							</a-radio-group>
						</a-row>
						<a-row style="padding: 0 15px 15px">
							<a-col :span="9"> {{ $t('table.loanReview.recommendLoanAmount') }}: </a-col>
							<a-col :span="15">
								<a-input-group compact>
									<a-select v-model="Modal.recommendAmountType" style="width: 40%" @change="getAmountCalculate">
										<a-select-option value="gross"> Gross </a-select-option>
										<a-select-option value="net"> Net </a-select-option>
									</a-select>
									<a-tooltip :visible="Modal.recommendAmount !== ''" placement="topLeft">
										<a-statistic slot="title" :value="formatNumber(Modal.recommendAmount)" class="format-number" />
										<a-input style="width: 60%" v-model="Modal.recommendAmount" suffix="Ksh" />
									</a-tooltip>
								</a-input-group>
								<p style="margin: 10px 10px 0; text-transform: capitalize"
									>estimated {{ Modal.recommendAmountType === 'gross' ? 'net' : 'gross' }} amount
									<a-statistic :value="Modal.otherRecommendAmount" class="recommend-amount" /> Ksh</p
								>
							</a-col>
						</a-row>
					</div>
				</div>
				<a-row style="padding: 0 15px 15px">
					<a-col :span="9"> {{ $t('table.loanReview.comments') }}: </a-col>
					<a-col :span="15">
						<a-input v-model="Modal.checkText" type="textarea" :maxLength="300" />
					</a-col>
				</a-row>
			</div>
		</a-modal>
	</div>
</template>
<script>
import { apiProcessCheck } from '@/api/check'
import { apiEmergencyCalculate, apiEmergencyCheckList } from '@/api/emergencyLoan'
import Record from '@/views/loanReview/components/Record'
export default {
	props: ['processStatus', 'loanPreCreditResponse', 'checkRecords', 'insuranceCompleted', 'riskBasedFinancing', 'loanType', 'repaymentCapacity'],
	data() {
		return {
			Modal: {
				show: false,
				checkText: '',
				recommendAmount: '',
				pass: 1,
				recommendAmountType: 'gross',
				otherRecommendAmount: 0
			}
		}
	},
	components: {
		Record
	},
	watch: {
		'Modal.recommendAmount'(val) {
			if (/^\+?[1-9][0-9]*$/.test(val)) {
				this.getAmountCalculate()
			} else {
				this.Modal.recommendAmount = ''
			}
		}
	},
	methods: {
		formatNumber(value) {
			value += ''
			const list = value.split('.')
			const prefix = list[0].charAt(0) === '-' ? '-' : ''
			let num = prefix ? list[0].slice(1) : list[0]
			let result = ''
			while (num.length > 3) {
				result = `,${num.slice(-3)}${result}`
				num = num.slice(0, num.length - 3)
			}
			if (num) {
				result = num + result
			}
			return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
		},
		getAmountCalculate() {
			if (!this.Modal.recommendAmount) {
				return false
			}
			apiEmergencyCalculate({
				loanId: this.$route.query.id,
				type: this.Modal.recommendAmountType,
				amount: this.Modal.recommendAmount
			}).then((res) => {
				this.Modal.otherRecommendAmount = res
			})
		},
		comfirmTip() {
			this.$confirm({
				content: (h) => (
					<div>
						Please check the <b>Loan Application</b> sector carefully first !
					</div>
				),
				okText: 'Confirm',
				onOk() {},
				onCancel() {},
				class: 'loantip'
			})
		},
		SendAudit() {
			this.Modal.pass = 1
			this.Modal.checkText = ''
			this.Modal.show = true
			if (this.repaymentCapacity < 2 && this.processStatus.nextNodeId === 'apply_info_confirm_node') {
				this.$message.error('Attention ⚠️ The capital of repayment is under 2.')
			}
		},
		handleOk() {
			const activeTab = this.processStatus.statusCode === 'disburse_info_check' ? 'loanDataReview' : 'applicationReview'
			let params = {
				loanId: this.$route.query.id,
				loanType: this.loanType,
				pass: this.Modal.pass,
				checkType: 'check',
				checkText: this.Modal.checkText,
				nodeId: this.processStatus.processNode.id
			}
			switch (activeTab) {
				// 申请资料审核
				case 'applicationReview':
					if (this.Modal.checkText == '' && (this.Modal.pass == 0 || this.Modal.pass == -2)) {
						this.$message.error('remark is required')
						return false
					}
					params.recommendAmountType = this.Modal.recommendAmountType
					params.recommendAmount = this.Modal.recommendAmount
					if (this.Modal.pass == 1 && this.processStatus.processNode.id === 'apply_info_confirm_node' && !this.insuranceCompleted) {
						this.$message.error('Please select whether to take insurance')
						return false
					}
					break
				// 放款资料审核
				case 'loanDataReview':
					if (this.Modal.checkText == '' && this.Modal.pass == 0) {
						this.$message.error('remark is required')
						return false
					}
					break
				default:
					break
			}
			// if (this.repaymentCapacity < 2 && this.processStatus.nextNodeId === 'apply_info_confirm_node') {
			// 	return false
			// 	// params.pass = 0
			// }
			apiProcessCheck(params).then((res) => {
				// if (this.repaymentCapacity < 2 && this.processStatus.nextNodeId === 'apply_info_confirm_node') {
				// 	this.$message.error('Submit Failed. The client can\'t qualify for the emergency (/ cash pap ).It will be rejected to the "Client List"')
				// } else {
				this.$message.success('success')
				// }
				this.$store.commit('tabs/delTab', this.$route.fullPath)
				this.$store.commit('tabs/setActiveTab', this.$route.query.from)
				this.$router.push(this.$route.query.from)
			})
		}
	}
}
</script>
<style lang="less">
.recommend-amount {
	display: inline-block;
	.ant-statistic-content {
		display: inline-block;
	}
}
.format-number {
	.ant-statistic-content {
		color: #fff;
	}
}
.numeric-input .ant-tooltip-inner {
	min-width: 32px;
	min-height: 37px;
}

.numeric-input .numeric-input-title {
	font-size: 14px;
}
</style>
