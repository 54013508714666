<template>
	<div style="display: inline-block">
		<a-button @click="briefModalShow = true"><img class="icon-btn" src="@/assets/Record.png" />{{ $t('operate.loanBrief') }} </a-button>
		<a-modal v-drag-modal :title="$t('operate.loanBrief')" :dialog-style="{ top: '60px' }" v-model="briefModalShow" width="928px">
			<a-table bordered :data-source="checkListData" :pagination="false" :columns="checkColumns" :showHeader="false">
				<template slot="checkList" slot-scope="text"> {{ $t(`loanBrief.${text}`) }} </template>
				<template slot="check" slot-scope="text, record">
					<a-input
						v-if="fillData.includes(record.modelValue)"
						style="margin: -5px 0"
						v-model="loanBriefData[record.modelValue]"
						:maxLength="100"
						placeholder="Please fill out"
					/>
					<p v-else>{{ text }}</p>
				</template>
			</a-table>
			<template slot="footer">
				<a-button key="submit" type="primary" @click="saveLoanBrief"> Submit </a-button>
			</template>
		</a-modal>
	</div>
</template>
<script>
import { apiEmergencyCheckList, apiEmergencyCheckListSave } from '@/api/emergencyLoan'
export default {
	data() {
		return {
			briefModalShow: false,
			loanBriefData: {},
			checkListData: [],
			fillData: [
				'customerBusiness',
				'loanPurpose',
				'loanOffer',
				'averageIncomeMpesa',
				'averageIncomeBank',
				'averageDailyBalance',
				'cpOne',
				'cpTwo',
				'cpThree',
				'cpFour',
				'repaymentCapacity',
				'conditionCollateral'
			],
			checkColumns: [
				{
					title: 'CheckList',
					width: 500,
					dataIndex: 'checkList',
					scopedSlots: {
						customRender: 'checkList'
					}
				},
				{
					title: 'Check',
					dataIndex: 'check',
					width: 300,
					scopedSlots: {
						customRender: 'check'
					}
				}
			]
		}
	},
	methods: {
		initFindLoanBrief() {
			apiEmergencyCheckList({ loanId: this.$route.query.id }).then((res) => {
				if (res === null) {
					this.loanBriefData = {
						emergencyId: '', //emergency or cash pap id，暂用不到
						loanId: this.$route.query.id, //案件id
						clientName: '', //客户姓名
						clientPhone: '', //客户手机号码
						saleAgent: null, //销售名称
						forceValue: null, //强制销售价值
						applyAmount: null, //申请金额，车贷
						installment: null, //申请期限
						balance: null, //当前贷款待还金额
						principalPaid: null, //已还本金
						currentInstallment: null, //当前期应还金额
						numOfPaidInstallment: null, //已付的期数
						collateral: null, //销售价值和估值的比值
						customerBusiness: '', //Customer business(Source of income)
						loanPurpose: '', //Loan purpose (Proof of Business/Employment)
						loanOffer: '', //Loan offer
						averageIncomeMpesa: '', //Average income - Mpesa (Remove loan )
						averageIncomeBank: '', //Average income - bank  (Remove loan )
						averageDailyBalance: '', //Average daily balance
						cpOne: '', //Credit policy 1 - car range (max loan offer)
						cpTwo: '', //Credit policy 2 - 40% monthly income MPESA
						cpThree: '', //Credit policy 3 - Maximum average balance daily
						cpFour: '', //Credit policy 4 - 40% Bank Statements Income Balance
						repaymentCapacity: '', //Repayment Capacity of the Client(Total Income/(Top UpInstalment+Current Installment)
						conditionCollateral: '' //Condition of Collateral provided(As per Valuation Report)
					}
				} else {
					this.loanBriefData = res
					this.$emit('setDataChange', {
						key: 'repaymentCapacity',
						value: this.loanBriefData.repaymentCapacity
					})
				}
				this.checkListData = [
					{
						checkList: 'customerName',
						check: this.loanBriefData.clientName,
						modelValue: 'customerName'
					},
					{
						checkList: 'customerTelNo',
						check: this.loanBriefData.clientPhone,
						modelValue: 'clientPhone'
					},
					{
						checkList: 'salesAgent',
						check: this.loanBriefData.saleAgent,
						modelValue: 'saleAgent'
					},
					{
						checkList: 'customerBusiness',
						check: this.loanBriefData.customerBusiness,
						modelValue: 'customerBusiness'
					},
					{
						checkList: 'loanPurpose',
						check: this.loanBriefData.loanPurpose,
						modelValue: 'loanPurpose'
					},
					{
						checkList: 'forcedSale',
						check: this.loanBriefData.forceValue,
						modelValue: 'forceValue'
					},
					{
						checkList: 'loanRequest',
						check: this.loanBriefData.applyAmount,
						modelValue: 'applyAmount'
					},
					{
						checkList: 'loanOffer',
						check: this.loanBriefData.loanOffer,
						modelValue: 'loanOffer'
					},
					{
						checkList: 'installments',
						check: this.loanBriefData.installment,
						modelValue: 'installment'
					},
					{
						checkList: 'averageIncomeMpesa',
						check: this.loanBriefData.averageIncomeMpesa,
						modelValue: 'averageIncomeMpesa'
					},
					{
						checkList: 'averageIncomeBank',
						check: this.loanBriefData.averageIncomeBank,
						modelValue: 'averageIncomeBank'
					},
					{
						checkList: 'averageDailyBalance',
						check: this.loanBriefData.averageDailyBalance,
						modelValue: 'averageDailyBalance'
					},
					{
						checkList: 'creditPolicyCarRange',
						check: this.loanBriefData.cpOne,
						modelValue: 'cpOne'
					},
					{
						checkList: 'creditPolicyMonthlyIncomeMpesa',
						check: this.loanBriefData.cpTwo,
						modelValue: 'cpTwo'
					},
					{
						checkList: 'creditPolicyMaximumAverageBalanceDaily',
						check: this.loanBriefData.cpThree,
						modelValue: 'cpThree'
					},
					{
						checkList: 'creditPolicyBankStatementsIncomeBalance',
						check: this.loanBriefData.cpFour,
						modelValue: 'cpFour'
					},
					{
						checkList: 'balanceonCurrentLoan',
						check: this.loanBriefData.balance,
						modelValue: 'balance'
					},
					{
						checkList: 'amountpaidonPrincipal',
						check: this.loanBriefData.principalPaid,
						modelValue: 'principalPaid'
					},
					{
						checkList: 'currentInstallment',
						check: this.loanBriefData.currentInstallment,
						modelValue: 'currentInstallment'
					},
					{
						checkList: 'noofMonthsPaid',
						check: this.loanBriefData.numOfPaidInstallment,
						modelValue: 'numOfPaidInstallment'
					},
					{
						checkList: 'collateral',
						check: this.loanBriefData.collateral,
						modelValue: 'collateral'
					},
					{
						checkList: 'repaymentCapacityOfTheClient2',
						check: this.loanBriefData.repaymentCapacity,
						modelValue: 'repaymentCapacity'
					},
					{
						checkList: 'conditionOfCollateralProvided',
						check: this.loanBriefData.conditionCollateral,
						modelValue: 'conditionCollateral'
					}
				]
			})
		},
		saveLoanBrief() {
			apiEmergencyCheckListSave(this.loanBriefData).then(() => {
				this.briefModalShow = false
				this.$emit('setDataChange', {
					key: 'repaymentCapacity',
					value: this.loanBriefData.repaymentCapacity
				})
			})
		}
	},
	created() {
		this.$nextTick(() => {
			this.initFindLoanBrief()
		})
	}
}
</script>
