<template>
	<a-tabs :defaultActiveKey="defaultActiveKey" type="card" :animated="true" style="margin-top: 20px">
		<a-tab-pane :key="1" :tab="$t(`table.applyInfo.financialLabel.loanMap.${caseDetailsLoanType}`)">
			<case-details
				v-if="!noneCaseDetails"
				:caseStates="'view_detail'"
				@getCaseDetailsData="getCaseDetailsData"
				:notify="false"
				:parentNode="'lendingdetail'"
			></case-details>
		</a-tab-pane>
		<a-tab-pane v-if="emergencyFormInfo" :key="2" :tab="$t(`table.applyInfo.financialLabel.loanMap.${emergencyFormInfo.loanInfo.loanType}`)">
			<viewer>
				<div
					class="action-btn"
					v-if="$route.query.from === '/home/emergencyLoan/dataReview' || $route.query.from === '/home/emergencyLoan/loanReview'"
				>
					<comments-popover />
					<a-popover v-model="recordPopoverShow" trigger="click" placement="topLeft">
						<a-row slot="title" class="comments-title">
							<a-col :span="16"> {{ $t('table.loanReview.record') }} </a-col>
							<a-col :span="8" style="text-align: right">
								<a-icon @click="recordPopoverShow = false" type="close" />
							</a-col>
						</a-row>
						<div slot="content" class="record-content">
							<record :loanPreCreditResponse="loanPreCreditResponse" :checkRecords="checkRecords" :riskBasedFinancing="riskBasedFinancing"></record>
						</div>
						<a-button><img class="icon-btn" src="@/assets/Record.png" />{{ $t('table.loanReview.record') }} </a-button>
					</a-popover>
					<loan-brief @setDataChange="setDataChange" />
					<submit-review
						v-if="processStatus"
						:processStatus="processStatus"
						:loanType="caseDetailsData.loanType"
						:loanPreCreditResponse="loanPreCreditResponse"
						:checkRecords="checkRecords"
						:repaymentCapacity.sync="repaymentCapacity"
						:riskBasedFinancing="riskBasedFinancing"
						:insuranceCompleted="insuranceCompleted"
					/>
				</div>
				<user-info :userInfo="userInfo" :loanInfo="detailData.loanInfo">
					<template slot="titleBtn">
						<template v-if="$route.query.from === '/home/emergencyLoan/clientList'">
							<a-button type="primary" @click="SendAudit(1)">{{ $t('operate.sendAudit') }}</a-button>
							<!-- <a-button type="primary" @click="Archive" style="margin-left: 20px">{{ $t('page.archive') }}</a-button> -->
						</template>
						<template v-if="$route.query.from === '/home/emergencyLoan/loanList'">
							<a-button type="primary" @click="SendAudit(0)">{{ $t('operate.sendAudit') }}</a-button>
							<a-button type="primary" @click="EditAudit($route.query.id)" style="margin: 0 20px">{{ $t('operate.editAudit') }}</a-button>
							<a-button type="primary" @click="Return">{{ $t('table.applyInfo.tabsMenu.notAccept') }}</a-button>
						</template>
						<template v-if="$route.query.from === '/home/emergencyLoan/pendingList'">
							<!-- <a-button type="primary" :disabled="caseDetailsData?.loanInfo?.disburseType !== 'choice'" @click="toDisburseNow">
								Disburse Now
							</a-button>
							<a-button type="primary" style="margin-left: 10px" @click="toRecordDisbursementEntries"> Record Disbursement Entries </a-button> -->
							<div class="f-1" />
							<pass-disburse-record-modal
								:loanId="caseDetailsData.id"
								:loanType="caseDetailsData.loanType"
								:processNodeId="caseDetailsData.processStatus?.processNode?.id"
								listPagePath="pendingList"
							>
								<a-button
									slot="reference"
									type="primary"
									:disabled="!$hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_CHECKER')"
									:class="{ 'btn-pass': $hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_CHECKER') }"
								>
									{{ $t('operate.comfireAmount') }}
								</a-button>
							</pass-disburse-record-modal>
							<a-button
								type="primary"
								:disabled="!($hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_MAKER') || $hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_CHECKER'))"
								:class="{
									'btn-reject': $hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_MAKER') && $hasPermission('ROLE_EMERGENCY_DISBURSE_WAITING_CHECKER')
								}"
								style="margin-left: 10px"
								@click="ShowFailModal"
							>
								{{ $t('operate.auditFail') }}
							</a-button>
						</template>
					</template>
				</user-info>
				<loan-form v-if="beingRevised" :beingRevised="beingRevised" :loanInfo.sync="emergencyFormInfo.loanInfo" @setDataChange="setDataChange" />
				<loan-detail
					v-else
					:loanInfo.sync="emergencyFormInfo.loanInfo"
					:detailData="detailData"
					:showEditBtn="$route.query.from === '/home/emergencyLoan/dataReview'"
					:isEditable="processStatus && processStatus.currentUserCanProcess && processStatus.processNode.id === 'apply_info_confirm_node'"
					@setDataChange="setDataChange"
				/>
				<a-descriptions v-if="emergencyFormInfo.logbook" bordered :title="$t('table.applyInfo.vehicleLabel.logFileInfo')" size="default">
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.logFile')" :span="3">
						<UploadPreviewFile :uploadFilePath="emergencyFormInfo.logbook.logbookFile" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
						{{ emergencyFormInfo.logbook.make }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
						{{ emergencyFormInfo.logbook.model }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
						{{ emergencyFormInfo.logbook.year }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
						{{ emergencyFormInfo.logbook.registrationNumber }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
						{{ emergencyFormInfo.logbook.frameNumber }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
						{{ emergencyFormInfo.logbook.engineNo }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.attribution')">
						<div class="box">
							<div class="list" v-for="(item, idx) in emergencyFormInfo.logbook.belongs" :key="idx">
								<div class="span_labe"> PIN:{{ emergencyFormInfo.logbook.belongs[idx].pin }} </div>
								<div class="span_labe"> Name:{{ emergencyFormInfo.logbook.belongs[idx].name }} </div>
							</div>
						</div>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicleUse')">
						{{ emergencyFormInfo.logbook.vehicleUse }}
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions
					v-if="emergencyFormInfo.loanInfo && emergencyFormInfo.loanInfo.loanType === 6"
					bordered
					:title="$t('table.applyInfo.vehicleLabel.estimateBookFileTxt')"
					size="default"
				>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimateBookFile')" :span="3">
						<UploadPreviewFile :uploadFilePath="emergencyFormInfo.valuation.file" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.vehicle')">
						{{ emergencyFormInfo.valuation.make }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.model')">
						{{ emergencyFormInfo.valuation.model }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.year')">
						{{ emergencyFormInfo.valuation.year }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.license')">
						{{ emergencyFormInfo.valuation.registrationNumber }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.frame')">
						{{ emergencyFormInfo.valuation.frameNumber }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.engineNo')">
						{{ emergencyFormInfo.valuation.engineNo }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.estimate')">
						{{ emergencyFormInfo.valuation.valuation }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.endValue')">
						{{ emergencyFormInfo.valuation.saleValuation }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.createTime')">
						{{ emergencyFormInfo.valuation.reportTime }}
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions :title="$t('table.applyInfo.vehicleLabel.insurance')" bordered size="default">
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.allInsurance')">
						{{ emergencyFormInfo.isAllRisks == 1 ? $t('page.yes') : $t('page.no') }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.expTimeRe')" :span="2">
						{{ emergencyFormInfo.expireTime }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.applyInfo.vehicleLabel.stickersPic')" :span="3">
						<UploadPreviewFile :uploadFilePath="emergencyFormInfo.insurancePic" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.financialLabel.flow')" size="default">
					<a-descriptions-item :span="3">
						<a-table
							style="font-size: 12px"
							:columns="columns"
							size="default"
							:rowKey="(record, index) => index"
							:dataSource="emergencyFormInfo.transactions"
							:pagination="false"
							bordered
						>
							<a slot="file" slot-scope="text, obj" @click="openUrl(obj)">{{ obj.file }}</a>
						</a-table>
					</a-descriptions-item>
					<a-descriptions-item>
						<a-button
							type="primary"
							@click="CheckLoanDetail"
							style="margin-left: 20px"
							:disabled="emergencyFormInfo.transactions && emergencyFormInfo.transactions.length == 0"
						>
							{{ $t('page.checkReview') }}
						</a-button>
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions bordered title="Loan Application" size="small">
					<a-descriptions-item label="Loan Application Form">
						<UploadPreviewFile :uploadFilePath="emergencyFormInfo.applicationFormFile" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions v-if="emergencyFormInfo.loanOfferFile" bordered title="loan offer" size="small">
					<a-descriptions-item :label="$t('table.loanInfo.addInfo.number')" :span="3">
						{{ emergencyFormInfo.loanOfferNo }}
					</a-descriptions-item>
					<a-descriptions-item :label="$t('table.loanInfo.addInfo.file')" :span="3">
						<UploadPreviewFile :uploadFilePath="emergencyFormInfo.loanOfferFile" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions v-if="emergencyFormInfo.expireDate" bordered title="Insurance Endorsement" size="small">
					<a-descriptions-item label="Insurance Endorsement Letter (optional)" :span="3">
						<UploadPreviewFile :uploadFilePath="emergencyFormInfo.endorsementLetters" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
					<a-descriptions-item label="Expiry Date" :span="3">
						{{ emergencyFormInfo.expireDate }}
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions v-if="emergencyFormInfo.loanCalculator" bordered title="Loan Calculator" size="small">
					<a-descriptions-item label="Loan Calculator">
						<UploadPreviewFile :uploadFilePath="emergencyFormInfo.loanCalculator" :isUploadFileBtn="false"> </UploadPreviewFile>
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions class="table-descriptions" :title="$t('table.applyInfo.applicationLabel.supDoc')" size="default">
					<a-descriptions-item>
						<a-table
							style="font-size: 12px"
							:columns="supCloums"
							size="default"
							:rowKey="(record, index) => index"
							:dataSource="supplementaryDocuments"
							:pagination="false"
							bordered
						>
							<span slot="file" slot-scope="obj">
								<a v-bind:href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.file)" target="_blank">{{
									!$Util.isEmpty(obj.file) ? obj.file.split('/').at(-1) : ''
								}}</a>
							</span>
						</a-table>
					</a-descriptions-item>
				</a-descriptions>
			</viewer>
			<!-- 流水弹窗 -->
			<a-modal v-drag-modal v-model="fullModal.show" title="Serial documents" :footer="false" width="100%" wrapClassName="full-modal">
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" :tab="$t('table.useraccount.result.tab1')">
						<information :details.sync="details" v-if="fullModal.show"></information>
					</a-tab-pane>
					<a-tab-pane key="2" :tab="$t('table.useraccount.result.tab3')">
						<flowDetails :reqParam="fileNameList" :isFileResult="true" v-if="fullModal.show"></flowDetails>
					</a-tab-pane>
					<a-tab-pane key="3" :tab="$t('table.useraccount.result.tab2')" force-render>
						<transactions :id="emergencyFormInfo.transactions" :isFileResult="true" v-if="fullModal.show"></transactions>
					</a-tab-pane>
				</a-tabs>
			</a-modal>
			<!-- <a-modal v-drag-modal :title="$t('table.applyInfo.titleMap.title1')" v-model="archiveModal.show" :row="6" @ok="archiveHandleOk">
				<a-textarea v-model="archiveModal.reason" :maxLength="300" />
			</a-modal> -->
			<a-modal v-drag-modal :title="$t('table.applyInfo.tabsMenu.notAccept')" v-model="returnModal.show" @ok="returnHandleOk" width="800px">
				<a-form-model :label-col="{ span: 2 }" :wrapper-col="{ span: 16 }">
					<a-form-model-item>
						{{ this.$t(`table.applyInfo.msgMap.msg3`) }}
					</a-form-model-item>
					<a-form-model-item :label="$t('table.loanReview.Seeting.modal.remark')">
						<a-input v-model="returnModal.checkText" type="textarea" :maxLength="300" />
					</a-form-model-item>
				</a-form-model>
			</a-modal>
			<a-modal v-drag-modal :title="$t('table.applyInfo.titleMap.title2')" width="800px" v-model="lendingModalFail.show" @ok="AuditFail">
				<a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
					<a-form-model-item :label="$t('table.loanReview.Seeting.modal.reason')">
						<a-radio-group v-model="lendingModalFail.checkFailType">
							<a-radio :value="item" v-for="item in [1, 0]" :key="item">
								{{ $t(`table.loanReview.Seeting.modal.reasonMap.${item}`) }}
							</a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item :label="$t('table.loanReview.Seeting.modal.remark')">
						<a-input style="height: 100px" v-model="lendingModalFail.checkText" type="textarea" :maxLength="300" />
					</a-form-model-item>
				</a-form-model>
			</a-modal>
		</a-tab-pane>
	</a-tabs>
</template>
<script>
import moment from 'moment'
import { _axios } from '@/plugins/axios'
import CaseDetails from '@/views/businessComponents/CaseDetails'
import userInfo from '@/views/businessComponents/UserInfo'
import loanForm from '@/views/emergencyLoan/components/loanForm.vue'
import loanDetail from '@/views/emergencyLoan/components/loanDetail.vue'
import information from '@/views/review/components/information'
import flowDetails from '@/views/review/components/flowDetails'
import transactions from '@/views/review/components/transactions'
import CommentsPopover from '@/views/emergencyLoan/components/CommentsPopover'
import LoanBrief from '@/views/emergencyLoan/components/LoanBrief'
import SubmitReview from '@/views/emergencyLoan/components/SubmitReview'
import Record from '@/views/emergencyLoan/components/Record'
import { apiEmergencyDetail, apiEmergencyCalculate } from '@/api/emergencyLoan'
import { apiProcessCheck, apiProcessFile } from '@/api/check'
import { apiLoanDetail, apiGetFileStatus } from '@/api/loan'
import PassDisburseRecordModal from '@/components/disburse/pass-disburse-record-modal.vue'

export default {
	components: {
		CaseDetails,
		userInfo,
		loanForm,
		loanDetail,
		information,
		transactions,
		flowDetails,
		CommentsPopover,
		LoanBrief,
		SubmitReview,
		Record,
		PassDisburseRecordModal
	},
	data() {
		return {
			defaultActiveKey: 2,
			dateFormat: 'YYYY-MM-DD HH:mm:ss',
			caseDetailsData: null,
			userInfo: null,
			emergencyFormInfo: null,
			beingRevised: false,
			showEditBtn: false,
			editable: false,
			columns: [
				{
					// 流水机构
					title: this.$t('table.applyInfo.financialLabel.bankType'),
					dataIndex: 'institutionName',
					align: 'center'
				},
				{
					// 文件名称
					title: this.$t('table.applyInfo.financialLabel.fileName'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件密码
					title: this.$t('table.applyInfo.financialLabel.filePassword'),
					dataIndex: 'filePassword',
					align: 'center'
				},
				{
					// 状态
					title: this.$t('table.loanInfo.caseInfo.status'),
					dataIndex: 'status',
					align: 'center',
					customRender: (v, o) => (v ? this.$t(`table.useraccount.file_status_map.${v}`) : '-')
				}
			],
			fullModal: {
				show: false
			},
			checkRecords: null,
			processStatus: null,
			loanPreCreditResponse: null,
			riskBasedFinancing: null,
			loanBriefListData: null,
			recordPopoverShow: false,
			insuranceCompleted: false,
			returnModal: {
				show: false,
				checkText: ''
			},
			lendingModalAmount: {
				show: false,
				payList: [
					{
						disburseName: '',
						disburseAccount: '',
						disburseType: '',
						disburseAmount: '',
						disburseCertificateFile: '',
						paidTime: null
					}
				]
			},
			totalDisburseAmount: 0,
			lendingModalFail: {
				show: false,
				checkText: '',
				checkFailType: 0
			},
			noneCaseDetails: false,
			caseDetailsLoanType: 0,
			repaymentCapacity: 0,
			detailData: {}
		}
	},
	computed: {
		supCloums() {
			return [
				{
					// 文件名称
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileName'),
					dataIndex: 'filename',
					align: 'center',
					width: 300
				},
				{
					// 文件
					title: this.$t('table.applyInfo.applicationLabel.supCloums.file'),
					scopedSlots: { customRender: 'file' },
					align: 'center'
				},
				{
					// 文件备注
					title: this.$t('table.applyInfo.applicationLabel.supCloums.fileRemark'),
					dataIndex: 'note',
					align: 'center'
				}
			]
		},
		fileNameList() {
			let fileNameList = []
			this.emergencyFormInfo.transactions.map((item) => {
				fileNameList.push(item.file)
			})
			return fileNameList
		},
		supplementaryDocuments() {
			const {supplementFiles, disburseSupplementFiles } = this.emergencyFormInfo
			const documentArray = [];
			if (supplementFiles) documentArray.push(...supplementFiles)
			if (disburseSupplementFiles) documentArray.push(...disburseSupplementFiles)
			return documentArray
		}
	},
	created() {
		this.$nextTick(() => {
			this.applyInfoInit()
		})
	},
	methods: {
		moment: moment,
		closeTab(targetKey) {
			this.$store.commit('tabs/delTab', targetKey)
			this.$store.commit('tabs/setActiveTab', this.$route.query.from)
		},
		getCaseDetailsData(val) {
			if (val === null) {
				this.noneCaseDetails = true
				return false
			}
			this.caseDetailsLoanType = val.loanInfo.loanType
		},
		applyInfoInit() {
			apiEmergencyDetail({
				id: this.$route.query.id
			})
				.then((res) => {
					this.userInfo = {
						createSource: res.createSource,
						createTime: res.emergencyFormInfo.createTime,
						creatorId: res.creatorId,
						creatorName: res.emergencyFormInfo.creatorName,
						customerId: res.customerId,
						statusCode: res.processStatus.statusCode,
						caseId: res.caseId,
						casePrefix: res.casePrefix
					}
					this.caseDetailsData = res
					this.emergencyFormInfo = res.emergencyFormInfo
					if (this.emergencyFormInfo && !this.emergencyFormInfo.transactions) {
						this.emergencyFormInfo.transactions = []
					}
					this.loanBriefListData = {
						basicFullName: res.personalInfo.basicFullName,
						mobiles: res.personalInfo.mobiles,
						salesmanName: res.salesmanName,
						incomeSource: res.personalInfo.incomeSource ? res.personalInfo.incomeSource : {},
						loanUse: res.personalInfo.loanUse,
						loanCarInfos: res.loanCarInfos,
						applyAmount: res.loanApplyInfo.applyAmount
					}
					this.checkRecords = res.checkRecords
					this.checkRecords.map((item, index) => {
						if (item.recommendAmountType && item.recommendAmount) {
							apiEmergencyCalculate({
								loanId: this.$route.query.id,
								type: item.recommendAmountType,
								amount: item.recommendAmount
							}).then((res) => {
								this.checkRecords[index].calAmount = res
							})
						}
					})
					this.loanPreCreditResponse = res.loanPreCreditResponse
					this.riskBasedFinancing = res.riskBasedFinancing
					this.processStatus = res.processStatus
					this.insuranceCompleted = true
					this.detailData = res
				})
				.finally(() => {
					if (!this.emergencyFormInfo) {
						this.defaultActiveKey = 1
					}
				})
		},
		setDataChange(params) {
			const key = params.key.split('.')
			key.length > 1 ? (this[key[0]][key[1]] = params.value) : (this[params.key] = params.value)
		},
		openUrl(val) {
			_axios
				.get(`/car/file/download/${val.file}`, { responseType: 'arraybuffer' })
				.then((res) => {
					window.open((window.URL || window.webkitURL).createObjectURL(new Blob([res], { type: 'application/pdf' })), '_blank')
				})
				.catch((err) => {
					console.log(err)
				})
		},
		// 查看流水解析结果
		CheckLoanDetail() {
			let fileNames = []
			this.emergencyFormInfo.transactions.map((item) => {
				fileNames.push(item.file)
			})
			let fileNamesStr = ''
			fileNames.forEach((element) => {
				fileNamesStr += 'fileNames=' + element + '&'
			})
			apiGetFileStatus(fileNamesStr).then((res) => {
				if (res) {
					this.emergencyFormInfo.transactions.forEach((element) => {
						element.status = res[element.file]
					})
				}
			})
			let params = {
				fileNames: '',
				date: this.moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
			}
			params.fileNames = fileNames.join(',')
			apiLoanDetail(params).then((res) => {
				this.details = res
				if (this.details) {
					this.fullModal.show = true
				}
			})
		},
		SendAudit(pass) {
			this.$store.commit('pageState/setUploadState', true)
			apiProcessCheck({
				loanId: this.$route.query.id,
				loanType: this.caseDetailsData.loanType,
				pass: pass,
				checkType: 'submit',
				checkText: '',
				checkFailType: '',
				nodeId: this.caseDetailsData.processStatus.processNode.id
			})
				.then(() => {
					this.$message.success('success')
					this.closeTab(this.$route.fullPath)
					this.$router.push(this.$route.query.from)
				})
				.finally(() => {
					this.$store.commit('pageState/setUploadState', false)
				})
		},
		EditAudit(id) {
			this.$router.push({
				path: 'loanInfo',
				query: {
					id,
					from: this.$route.path
				}
			})
		},
		// Archive() {
		// 	this.archiveModal.reason = ''
		// 	this.archiveModal.show = true
		// },
		Return() {
			this.returnModal.show = true
			this.returnModal.checkText = ''
		},
		returnHandleOk() {
			if (this.returnModal.checkText == '') {
				this.$message.error('remark is required')
				return false
			}
			apiProcessCheck({
				loanId: this.$route.query.id,
				loanType: this.caseDetailsData.loanType,
				pass: 0,
				checkType: 'check',
				checkFailType: '0',
				checkText: this.returnModal.checkText,
				nodeId: this.caseDetailsData.processStatus.processNode.id
			}).then(() => {
				this.$message.success('success')
				this.closeTab(this.$route.fullPath)
				this.$router.push(this.$route.query.from)
			})
		},
		checkDisburseAmount() {
			return (
				this.totalDisburseAmount ===
				this.lendingModalAmount.payList.reduce((pre, cur) => {
					return pre + cur.principalDue
				}, 0)
			)
		},
		Cancel() {
			this.lendingModalAmount = {
				show: false,
				payList: [
					{
						disburseName: '',
						disburseAccount: '',
						disburseType: '',
						disburseAmount: '',
						disburseCertificateFile: '',
						url: '',
						fileName: '',
						paidTime: null
					}
				]
			}
			this.$refs.ruleForm.resetFields()
		},
		ShowFailModal() {
			this.lendingModalFail = {
				show: true,
				checkText: '',
				checkFailType: 0
			}
		},
		AuditFail() {
			if (this.lendingModalFail.checkText) {
				if (this.lendingModalFail.checkText == '' && this.lendingModalFail.checkFailType == 0) {
					this.$message.error('remark is required')
					return false
				}
				let param = {
					loanId: this.$route.query.id,
					loanType: this.caseDetailsData.loanType,
					pass: 0,
					checkType: 'check',
					checkFailType: this.lendingModalFail.checkFailType,
					checkText: this.lendingModalFail.checkText,
					nodeId: this.caseDetailsData.processStatus.processNode.id
				}
				apiProcessCheck(param).then((res) => {
					this.$message.success('success')
					this.closeTab(this.$route.fullPath)
					this.$router.push(this.$route.query.from)
				})
			} else {
				this.$message.error('remark is required')
			}
		}
		// toDisburseNow() {
		// 	this.$router.push({
		// 		path: 'disburseNow',
		// 		query: {
		// 			loanId: this.caseDetailsData.id,
		// 			loanType: this.caseDetailsData.loanType
		// 		}
		// 	})
		// },
		// toRecordDisbursementEntries() {
		// 	this.$router.push({
		// 		path: 'recordDisbursementEntries',
		// 		query: {
		// 			loanId: this.caseDetailsData.id,
		// 			loanType: this.caseDetailsData.loanType,
		// 			disburseType: this.caseDetailsData.loanInfo.disburseType || 'dtb'
		// 		}
		// 	})
		// }
	}
}
</script>

<style scoped lang="less">
.ant-form-item {
	margin-bottom: 0;
}
.ant-input-number {
	width: 100%;
}
.file {
	left: 0;
	top: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.btn-pass {
	background-color: #67c23a;
	border-color: #67c23a;
}
.btn-reject {
	background-color: #e6a23c;
	border-color: #e6a23c;
}
</style>
